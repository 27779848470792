import "./AboutLAKStyles.css";
import DisplayPlanAction from "./DisplayPlanAction";
import DisplayStatuts from "./DisplayStatuts";
import DisplayReglements from "./DisplayReglements";

function AboutLAK() {

  return (
    <div className="about-container">
      <h1>Notre Histoire</h1>
      <p>
        Les Amis du Kwilu, en abrégé LAK, est une association sans but lucratif
        qui a été crééé en octobre 2022 pour encourager l'esprit d'initiative et 
        entrepreneurial et favoriser la réalisation des projets de développement 
        de petite, moyenne et grande envergure dans la province du Kwilu. 
        
        Depuis octobre 2022, plusieurs dizaines d'amis du Kwilu se sont enregistrés 
        et contribuent à la réalisation des objectifs de LAK, notamment l'encouragement 
        et la création des unités de production par la population locale habitant les 
        cinq territoires de la province du Kwilu, à savoir Bagata, Bulungu, Gungu, Idiofa 
        et Masi-Manimba.
      </p>

      <h1>Notre Mission</h1>
      <p>
        La mission de "Les Amis du Kwilu" est d'atteindre un horizon où les
        capacités et le savoir-faire de ses membres permettent la réalisation
        des objectifs de cette association et répondent, in fine, aux priorités 
        de développement socio-économique de la population du Kwilu, dans la 
        transparence et la rigueur.
      </p>

      <h1>Notre Vision</h1>
      <p>
        La vision de "Les Amis du Kwilu" est de mobiliser ses membres et 
        leurs savoir-faire pour réaliser des projets de développement 
        socio-économiques centrés sur les besoins des habitants de la province
        du Kwilu tout en bénéficiant et en conservant la confiance des partenaires
        d'affaires.
      </p>

      <h2>Nos Statuts</h2>
      <p>Les statuts de LAK sont accessibles en cliquant sur le bouton ci-dessous :</p>

      <DisplayStatuts />

      <h2>Nos réglements d'ordre intérieur</h2>
      <p>Les règlements d'ordre intérieur de LAK sont accessibles en cliquant sur le bouton ci-dessous :</p>

      <DisplayReglements />

      <h2>Notre plan d'action 2023</h2>
      <p>Le plan d'action 2023 de LAK est accessible en cliquant sur le bouton ci-dessous :</p>

      <DisplayPlanAction />

      <h2>Notre équipe</h2>
      <p>L'équipe de "Les Amis du Kwilu" est formée de membres suivants :</p>
    </div>
  );
}

export default AboutLAK;
