import "./InsightStyles.css";

function InsightData(props) {
  return (
    <div className="i-card">
      <div className="i-image">
        <img alt="actionimage" src={props.image} />
      </div>
      <h4>{props.heading}</h4>
      <p>{props.text}</p>
    </div>
  );
}

export default InsightData;
