export const MenuItems = [
  {
    title: "Accueil",
    url: "/",
    cName: "nav-links",
    icon: "fa-solid fa-house-user"
  },
  {
    title: "Notre association",
    url: "/association",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info"
  },
  {
    title: "Réalisations",
    url: "/realisations",
    cName: "nav-links",
    icon: "fa-solid fa-house-user"
  },
  {
    title: "Faire un don",
    url: "/faireundon",
    cName: "nav-links",
    icon: "fa-solid fa-house-user"
  },
  {
    title: "Investir au Kwilu",
    url: "/toutsurlekwilu",
    cName: "nav-links",
    icon: "fa-solid fa-briefcase"
  },
  {
    title: "Devenir membre",
    url: "/devenirmembre",
    cName: "nav-links-mobile"
  }
];
