import Navbar from "../components/Navbar";
import Entete from "../components/Entete";
import Situationactuelle from "../components/Situationactuelle";
import Insight from "../components/Insight";
import Footer from "../components/Footer";

function Accueil() {
  return (
    <div>
      <Navbar />
      <Entete
        cName="entete"
        enteteImg="https://images.unsplash.com/photo-1471771450139-6bfdb4b2609a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80"
        title="S'impliquer pour faire la différence."
        text="Encourageons l'entrepreunership de la population du Kwilu pour lutter contre la pauvreté criante."
        buttonText="Faites un don"
        url="/"
        btnClass="show"
      />
      <Situationactuelle />
      <Insight />
      <Footer />
    </div>
  );
}

export default Accueil;
