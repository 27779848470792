import "../styles.css";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const DisplayPlanAction = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow  = () => setShow(true);

  const docs = [
    { uri: require("../documents/PlanAction2023.pdf") }
  ];

  return (
    <div className="decoration">
      <Button className="button-decor" variant="primary" onClick={handleShow}>
        Voir le plan d'action 2023
      </Button>

      <Modal backdrop="static" show={show} onHide={handleClose}>
        <Modal.Body>
          <DocViewer
            className="docViewerStyle"
            pluginRenderers={DocViewerRenderers}
            documents={docs}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false
              }
            }}
            style={{ height: 450, width: 450}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DisplayPlanAction;