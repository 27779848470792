import "./FooterStyles.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div>
          <div>
            <i className="fa-sharp fa-solid fa-people-roof logo"></i>
            <h3>Les Amis du Kwilu</h3>
          </div>
          <div>
            <p>S'impliquer pour faire la différence.</p>
          </div>
        </div>
        <div>
          <a href="/">
            <i className="fa-brands fa-facebook-square" />
          </a>
          <a href="/">
            <i className="fa-brands fa-instagram-square" />
          </a>
          <a href="/">
            <i className="fa-brands fa-behance-square" />
          </a>
          <a href="/">
            <i className="fa-brands fa-twitter-square" />
          </a>
        </div>
      </div>
      <div className="bottom">
        <div>
          <h4>Les territoires du Kwilu</h4>
          <a href="/bagata">Bagata</a>
          <a href="/bulungu">Bulungu</a>
          <a href="/gungu">Gungu</a>
          <a href="/idioda">Idiofa</a>
          <a href="/masimanimba">Masi Manimba</a>
        </div>
        <div>
          <h4>Investir au Kwilu</h4>
          <a href="/agriculture">Agriculture</a>
          <a href="/elevage">Elevage</a>
          <a href="/immobilier">Immobilier</a>
          <a href="/transport">Transport</a>
        </div>
        <div>
          <h4>Communauté</h4>
          <a href="/">Agriculture</a>
          <a href="/">Elevage</a>
          <a href="/">Immobilier</a>
          <a href="/">Transport</a>
        </div>
        <div>
          <h4>Support</h4>
          <a href="/contact">Nous contacter</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
