import "./EnteteStyles.css";

function Entete(props) {
  return (
    <div>
      <div className={props.cName}>
        <img alt="entete" src={props.enteteImg} />
      </div>

      <div className="entete-text">
        <h1>{props.title}</h1>
        <p>{props.text}</p>
        <a href={props.url} className={props.btnClass}>
          {props.buttonText}
        </a>
      </div>
    </div>
  );
}

export default Entete;
