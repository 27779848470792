import Navbar from "../components/Navbar";
import Entete from "../components/Entete";
import associationImg from "../assets/africanteam.png";
import Footer from "../components/Footer";
import AboutLAK from "../components/AboutLAK";

function Association() {
  return (
    <div>
      <Navbar />
      <Entete
        cName="entete-association"
        enteteImg={associationImg}
        title="Notre association."
        btnClass="hide"
      />
      <AboutLAK />
      <Footer />
    </div>
  );
}

export default Association;
