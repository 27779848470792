import Navbar from "../components/Navbar";
import Entete from "../components/Entete";
import toutsurlekwiluImg from "../assets/arbres.jpg";
import Footer from "../components/Footer";

function Toutsurlekwilu() {
  return (
    <div>
      <Navbar />
      <Entete
        cName="entete-association"
        enteteImg={toutsurlekwiluImg}
        title="Investir dans le Kwilu"
        btnClass="hide"
      />
      <Footer />
    </div>
  );
}

export default Toutsurlekwilu;
