import "./ContactFormStyles.css";

function ContactForm() {
  return (
    <div className="form-container">
      <h1>Envoyer nous un message!</h1>
      <form>
        <input placeholder="Nom" />
        <input placeholder="Courriel" />
        <input placeholder="Sujet" />
        <textarea placeholder="Message" rows="4"></textarea>
        <button>Envoyer le message</button>
      </form>
    </div>
  );
}

export default ContactForm;
