import Navbar from "../components/Navbar";
import Entete from "../components/Entete";
import faireundonImg from "../assets/workingmen.jpg";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

function Faireundon() {
  return (
    <div>
      <Navbar />
      <Entete
        cName="entete-association"
        enteteImg={faireundonImg}
        title="Pour faire un don..."
        btnClass="hide"
      />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Faireundon;
