import Navbar from "../components/Navbar";
import Entete from "../components/Entete";
import realisationsImg from "../assets/riviere.jpg";
import Footer from "../components/Footer";

function Realisations() {
  return (
    <div>
      <Navbar />
      <Entete
        cName="entete-association"
        enteteImg={realisationsImg}
        title="Voici nos réalisations"
        btnClass="hide"
      />
      <Footer />
    </div>
  );
}

export default Realisations;
