import EntrepreneurData from "./EntrepreneurData";
import "./SituationactuelleStyles.css";
import ecole from "../assets/ecole.jpg";
import poulailler from "../assets/poulailler.jpg";
import photo from "../assets/photo3.jpg";
import workingmen from "../assets/workingmen.jpg";

const Situationactuelle = () => {
  return (
    <div className="situationactuelle">
      <h1>La situation socio-économique catastrophique du Kwilu.</h1>
      <p>Tous les indicateurs de développement humain sont au rouge</p>

      <EntrepreneurData
        className="situationactuelle-first"
        heading="Le rapport sur le Développement Humain 2021/2022"
        text="La République Démocratique du Congo est l’une des 5 nations parmi les plus pauvres du monde. En 2021, près de 64% de la population du pays - un peu moins de 60 Millions de personnes-vivaient avec moins de 2.15 dollars américains par jour. Ainsi, près d’une personne sur six en situation d’extrême pauvreté en Afrique subsaharienne vit en RDC. Selon le « Rapport sur le Développement Humain 2021/2022 », le classement IDH, l’Indice de développement Humain le situe à la 179ème place sur 191. Le défi est donc énorme mais pas insurmontable."
        img1={ecole}
        img2={poulailler}
      />

      <EntrepreneurData
        className="situationactuelle-first-reverse"
        heading="Un taux de chomage élévé"
        text="La République Démocratique du Congo est l’une des 5 nations parmi les plus pauvres du monde. En 2021, près de 64% de la population du pays - un peu moins de 60 Millions de personnes-vivaient avec moins de 2.15 dollars américains par jour."
        img1={photo}
        img2={workingmen}
      />
    </div>
  );
};

export default Situationactuelle;
