import InsightData from "./InsightData";
import "./InsightStyles.css";
import action1 from "../assets/arbres.jpg";
import action2 from "../assets/piggy.jpg";
import action3 from "../assets/riviere.jpg";

function Insight() {
  return (
    <div className="insight">
      <h1>Les actions pour contrer la pauvreté</h1>
      <p>Plusieurs actions sont à faire</p>
      <div className="insightcard">
        <InsightData
          image={action1}
          heading="Favoriser l'entrepreuriat local"
          text="La République Démocratique du Congo est l’une des 5 nations parmi les plus pauvres du monde. En 2021, près de 64% de la population du pays - un peu moins de 60 Millions de personnes-vivaient avec moins de 2.15 dollars américains par jour."
        />
        <InsightData
          image={action2}
          heading="Faire l'élevage des porcs"
          text="La République Démocratique du Congo est l’une des 5 nations parmi les plus pauvres du monde. En 2021, près de 64% de la population du pays - un peu moins de 60 Millions de personnes-vivaient avec moins de 2.15 dollars américains par jour."
        />
        <InsightData
          image={action3}
          heading="Révitaliser les cours d'eau"
          text="La République Démocratique du Congo est l’une des 5 nations parmi les plus pauvres du monde. En 2021, près de 64% de la population du pays - un peu moins de 60 Millions de personnes-vivaient avec moins de 2.15 dollars américains par jour."
        />
      </div>
    </div>
  );
}

export default Insight;
