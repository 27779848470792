  import "../styles.css";
  import React, { useState } from "react";
  import { Button, Modal } from "react-bootstrap";
  import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
  
  const DisplayReglements = () => {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow  = () => setShow(true);
  
    const docs = [
      { uri: require("../documents/ReglementOrdreInterieur.pdf") }
    ];
  
    return (
      <div className="decoration">
        <Button className="button-decor" variant="primary" onClick={handleShow}>
          Voir règlements d'ordre intérieur
        </Button>
  
        <Modal backdrop="static" show={show} onHide={handleClose}>
          <Modal.Body>
            <DocViewer
              className="docViewerStyle"
              pluginRenderers={DocViewerRenderers}
              documents={docs}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false
                }
              }}
              style={{ height: 450, width: 450}}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  
  export default DisplayReglements;