import "./styles.css";
import { Route, Routes } from "react-router-dom";
import Accueil from "./routes/Accueil";
import Association from "./routes/Association";
import Realisations from "./routes/Realisations";
import Faireundon from "./routes/Faireundon";
import Toutsurlekwilu from "./routes/Toutsurlekwilu";

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/association" element={<Association />} />
        <Route path="/realisations" element={<Realisations />} />
        <Route path="/faireundon" element={<Faireundon />} />
        <Route path="/toutsurlekwilu" element={<Toutsurlekwilu />} />
      </Routes>
    </div>
  );
}
